import AvailabilityManager from "../../services/book/availabilityManager";
import AvailabilitySorter from "../../services/book/availabilitySorter";
import AvailabilityFilter from "../../services/book/availabilityFilter";
import MapManager from "../../services/map/mapManager";
import SuggestedChollosSliderManager from "../../services/suggestedChollosSliderManager";
import LocationManager from "../../util/maps/locationManager";
import UserLocationManager from "../../util/maps/userLocationManager";
import SliderManager from "../../services/slider/sliderManager";
import {MapsPrinter} from "../../services/map/mapPrinter";

$(function ()
{
    myApp.book.availabilityManager = new AvailabilityManager();
    myApp.book.availabilitySorter = new AvailabilitySorter();
    myApp.book.availabilityFilter = new AvailabilityFilter();
    new MapManager();
    myApp.suggestedChollosSliderManager = new SuggestedChollosSliderManager();
    myApp.locationManager = new LocationManager();
    myApp.userLocationManager = new UserLocationManager();
    const sliderManager = new SliderManager();
    myApp.mapPrinter = new MapsPrinter();
    sliderManager.addSlider('[data-entity="slider-group"]', null, 'bullets', 5);
});
