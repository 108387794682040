import Group from "../../entity/group";
import Chollo from "../../entity/chollo";

export default class AvailabilityManager {

    constructor() {
        this.availabilityList = [];
        this.categories = ['thematic', 'featureddates', 'mealplan', 'accommodationcategory', 'rating', 'price', 'freecancellation', 'location', 'whentravel'];
        this.initialize();
    }

    initialize() {
        this.initGroups();
        this.initGroupsAndChollos();
    };

    initGroupsAndChollos() {
        $.each($('[data-entity="chollo"]'), (i, o) => {
            const value = $(o);
            const groupId = value.closest("[data-chollos-group]").data('chollos-group');
            const groupDomElement = $('[data-entity="group"][data-id="' + groupId + '"]');
            const group = new Group();

            group.id = groupDomElement.data('id');
            group.name = groupDomElement.data('name');
            group.route = groupDomElement.data('route');
            group.latitude = groupDomElement.data('latitude');
            group.longitude = groupDomElement.data('longitude');
            group.rate = null;
            if (typeof groupDomElement.data('rate') === 'number') {
                group.rate = groupDomElement.data('rate');
            }
            group.time = groupDomElement.data('time');
            group.price.desc = groupDomElement.data('price');
            group.price.asc = groupDomElement.data('price');
            group.type = groupDomElement.data('type');


            this.setNoVisibleGroups();
            this.setChollosToGroup(value, group.id);
            this.setMaxAndMinPriceGroup(group.id);
            if (!this.findGroup(group.id)) {
                this.availabilityList.push(group);
            }
        });
    };

    initGroups() {
        $.each($('[data-entity="group"]'), (i, value) => {
            const group = new Group();
            const groupDomElement = $(value);
            group.id = groupDomElement.data('id');
            group.name = groupDomElement.data('name');
            group.route = groupDomElement.data('route');
            group.latitude = groupDomElement.data('latitude');
            group.longitude = groupDomElement.data('longitude');
            group.time = groupDomElement.data('time');
            group.price.desc = groupDomElement.data('price');
            group.price.asc = groupDomElement.data('price');
            group.type = groupDomElement.data('type');
            group.pets = groupDomElement.data('filter-allowpets');
            group.class = groupDomElement.data('chollo-class');
            group.isExtra = groupDomElement.data('is-extra');
            this.setTags(groupDomElement, group);
            if (groupDomElement.data('filter-allowpets') === 1) {
                group.categories['allowpets'][0] = 1;
            }

            group.rate = null;
            if (typeof groupDomElement.data('rate') === 'number') {
                group.rate = groupDomElement.data('rate');
            }
            this.availabilityList.push(group);
        });

        document.querySelectorAll('[data-entity="timer-container"]').forEach(item => {
            item.addEventListener('click', (event) => {
                let timerText = item.querySelector('[data-attribute="timer-text"]').textContent;

                if (timerText) {
                    let modalTitle = document.querySelector(item.getAttribute('custom-modal') + ' [data-entity="custom-modal-title"]');
                    modalTitle.textContent = timerText;
                }
            });
        });
    };

    findGroup(idGroup) {
        let found = false;
        $.each(this.availabilityList, (index, group) => {
            if (group.id === idGroup) {
                found = true;
                return false;
            }
        });
        return found;
    };

    findGroupByField(field, value) {
        return this.findAllGroupsByField(field, value)[0];
    };

    findAllVisibleGroups() {
        const groups = [];
        $.each(this.availabilityList, (index, group) => {
            groups.push(group);
        });
        return groups;
    };

    findAllGroupsByField(field, value) {
        const groupsByField = [];
        $.each(this.availabilityList, (index, group) => {
            if (group[field] === value) {
                groupsByField.push(group);
            }
        });
        return groupsByField;
    };

    findAllVisibleGroupsByField(field, value) {
        const groups = [];
        $.each(this.findAllVisibleGroups(), (index, group) => {
            if (group[field] == value) {
                groups.push(group);
            }
        });
        return groups;
    };

    setNoVisibleGroups() {
        $.each(this.availabilityList, (index, group) => {
            group.visible = false;
        });
    };

    setVisibleGroups() {
        $.each(this.availabilityList, (index, group) => {
            if (group.type !== Group.prototype.typeComingSoon) {
                group.visible = true;
            }
        });
    };

    setChollosToGroup(cholloDomElement, groupId) {
        const group = this.findGroupByField('id', groupId);
        const chollo = new Chollo();
        chollo.id = cholloDomElement.data('id');
        chollo.price = cholloDomElement.data('price');
        chollo.latitude = cholloDomElement.data('latitude');
        chollo.longitude = cholloDomElement.data('longitude');
        chollo.rate = cholloDomElement.data('rate');
        chollo.route = cholloDomElement.data('route');
        chollo.dateId = cholloDomElement.find('[data-date-id]').data('date-id');
        chollo.mealPlanTag = cholloDomElement.data('mealplan')
        group.chollos.push(chollo);
    };

    setMaxAndMinPriceGroup(groupId) {
        const prices = [];
        const group = this.findGroupByField('id', groupId);
        $.each(group.chollos, (index, chollo) => {
            prices.push(chollo.price);
        });
        group.price.asc = Math.min.apply(Math, prices);
        group.price.desc = Math.max.apply(Math, prices);
    };

    setTags(groupDomElement, group) {
        $.each(this.categories, (index, category) => {
            const tags = groupDomElement.data('filter-' + category);
            this.setTag(category, group, tags);
        });
    };

    setTag(category, group, tagsIds) {
        let tags = [];
        if (typeof tagsIds == 'number') {
            tags.push(tagsIds);
        } else if (tagsIds && tagsIds != '' && tagsIds.indexOf(";") != -1) {
            tags = tagsIds.split(";");
        }
        if (category == 'accommodationcategory') {
            group.categories[category].push(parseInt(tags));
        } else {
            $.each(tags, (index, tag) => {
                group.categories[category].push(parseInt(tag));
            });
        }
    };

    reorderBanners() {
        $('[data-banner]').hide();
        document.querySelectorAll('[data-banner]').forEach((banner) => {
            const parent = $(banner).parent();
            const childCount = parent.find('> :visible').length;
            const bannerIndex = parseInt(banner.getAttribute('data-position'));
            if (bannerIndex < childCount - 1) {
                $(banner).insertAfter(parent.find('> :visible').eq(bannerIndex))
                $(banner).show();
            }
        })
    }
};

