import MapsLoader from "./mapsLoader";
import {MarkerClusterer} from "@googlemaps/markerclusterer";
import AjaxManager from "../ajaxManager";

export const GROUP_TYPE = 'GROUP';
export const CHOLLO_TYPE = 'CHOLLO';

export default class MapManager {
    constructor() {
        this.eventDelegation();
    }

    initialize() {
        this.mapsLoader = new MapsLoader();
        this.mapId = '#group-map';
        this.markers = [];
        this.markersPositions = [];
        this.selectedMarker = null;
        this.selectedPositionGroup = null;
        this.groups = myApp.book.availabilityManager.availabilityList;
        this.ajaxManager = new AjaxManager();
        this.data = [];
        this.selectedGroupId = myApp.urlManager.getUrlParam('openMap');
        this.selectedGroup = null;

        this.initMap().then();
    };

    async initMap() {
        const {Map} = await google.maps.importLibrary("maps");
        const {AdvancedMarkerElement} = await google.maps.importLibrary("marker");
        this.map = new Map(document.querySelector(this.mapId), this.mapsLoader.getOptions());
        const clusterMarkers = this.appendEntities(AdvancedMarkerElement, this.groups, typeof isChollos !== 'undefined' ? CHOLLO_TYPE : GROUP_TYPE);

        this.initGoogleMap(clusterMarkers, this.map, AdvancedMarkerElement);
    }

    initGoogleMap(clusterMarkers, map, AdvancedMarkerElement) {
        if (clusterMarkers.length > 1) {
            new MarkerClusterer({
                map: map,
                markers: clusterMarkers,
                renderer: {
                    render: ({count, position}) =>
                        this.createClusterMarker(count, position, AdvancedMarkerElement)
                }
            });
        }

        const bounds = new google.maps.LatLngBounds();


        if (!bounds.isEmpty() && !this.selectedGroupId) {
            this.map.fitBounds(bounds);
        }
    }

    createClusterMarker(count, position, AdvancedMarkerElement) {
        const clusterContainer = document.createElement('div');
        clusterContainer.classList.add('cluster-marker');
        clusterContainer.innerHTML = String(count);

        return new AdvancedMarkerElement({
            position,
            content: clusterContainer
        })
    }

    appendEntities(AdvancedMarkerElement, entities, entityType) {
        const advancedMarkers = this.buildMarkers(AdvancedMarkerElement, entities, entityType);
        if ((entities.length === 1 && advancedMarkers.length === 1) || (this.selectedMarker && this.selectedGroup && this.selectedPositionGroup)) {
            this.map.setCenter(this.selectedPositionGroup.position);
            this.map.setZoom(this.map.getZoom() + 10);
            this.selectDefaultMarker(this.selectedGroup, this.selectedMarker, entityType);
        }
        return advancedMarkers;
    }

    buildMarkers(AdvancedMarkerElement, entities, entityType) {
        const advancedMarkers = [];
        this.map.setCenter({
            lat: parseFloat(entities[0].lat ? entities[0].lat : (entities[0].latitude ? entities[0].latitude : entities[0].location.latitude)),
            lng: parseFloat(entities[0].lon ? entities[0].lon : (entities[0].longitude ? entities[0].longitude : entities[0].location.longitude))
        });

        entities.forEach(entity => {
            try {
                if (entity.visible == true && entity.class !== 'sold_out') {

                    const marker = this.buildMarker(entity);
                    this.markers.push(marker);
                    let zIndex = 1;

                    if (entity.highlighted) {
                        zIndex = 9999999;
                    }

                    let latitude = parseFloat(entity.lat ? entity.lat : (entity.latitude ? entity.latitude : entity.location.latitude));
                    let longitude = parseFloat(entity.lon ? entity.lon : (entity.longitude ? entity.longitude : entity.location.longitude));
                    while (true) {
                        if (!this.markersPositions.includes(latitude + ', ' + longitude)) {
                            break;
                        }
                        latitude += 0.0001;
                        longitude += 0.0001;
                    }

                    const markerElement = new AdvancedMarkerElement({
                        position: {
                            lat: latitude,
                            lng: longitude
                        },
                        map: this.map,
                        content: marker,
                        gmpClickable: true,
                        zIndex: zIndex
                    });

                    if (this.selectedGroupId == entity.id) {
                        this.selectedGroup = entity;
                    }

                    this.markersPositions.push(latitude + ', ' + longitude);
                    advancedMarkers.push(markerElement);

                    markerElement.addListener('click', (e) => {
                        this.onClickMarker(entity, marker, entityType);
                    });

                    if (entity.id == this.selectedGroupId) {
                        this.selectedMarker = marker;
                        this.selectedPositionGroup = markerElement;
                    }
                }
            } catch (e) {
                console.log(entity.id);
            }
        });

        this.map.addListener("click", () => {
            this.unselectMarkers(this.markers);
        });

        return advancedMarkers;
    }

    unselectMarkers(markers) {
        markers.forEach((marker) => {
            marker.classList.remove('marker-selected');
            const icon = marker.querySelector('i.ci');
            if (icon) {
                icon.classList.add('ci-s-13');
                icon.classList.remove('ci-s-18');
            }
        });

        document.querySelector('[data-own-infowindow]').style.display = 'none';
    }

    eventDelegation() {
        const modalGroupMap = $('#modal_group_map');
        modalGroupMap.on('custom-modal-show', () => {
            this.initialize();
            if (!this.selectedGroupId) {
                myApp.urlManager.setUrlParam('openMap', '');
            }
        });
        modalGroupMap.on('custom-modal-hidden', () => {
            myApp.urlManager.removeUrlParam('openMap');
        });
    }

    buildMarker(entity) {
        const marker = document.createElement('div');
        marker.classList.add('marker');
        const markerContainer = document.createElement('div');

        let entityPrice = entity.price.desc;
        marker.id = entity.id;

        if (entityPrice) {
            markerContainer.classList.add('d-flex', 'align-items-center', 'gap-1');
            markerContainer.setAttribute('data-entity', 'marker-text-container');

            const markerText = document.createElement('span');
            markerText.innerHTML = entityPrice + " €";
            markerText.setAttribute('data-entity', 'marker-text');
            markerText.classList.add('no-wrap');
            markerContainer.appendChild(markerText);
        } else {
            marker.classList.add('marker-no-price');
        }

        marker.appendChild(markerContainer);
        return marker;
    }

    onClickMarker(entity, marker, entityType) {
        entity.isVisited = true;
        marker.classList.add('marker-visited');
        this.selectedGroupId = null;
        this.selectedGroup = null;
        this.unselectMarkers(this.markers);
        this.updateCustomInfoWindow(entity, marker, entityType);
        this.selectMarker(marker);
    }

    selectDefaultMarker(entity, marker, entityType) {
        this.updateCustomInfoWindow(entity, marker, entityType);
        this.selectMarker(marker);
    }


    updateCustomInfoWindow(entity, marker, entityType) {
        if (!myApp.book.availabilityManager.availabilityList) return;
        let url = myApp.config.routing.getGroupInfoWindow;
        let callback = (data) => {
            if (data != "") {
                this.data[entity.id] = data;
                myApp.mapPrinter.activateMarker(marker, data);
            }
        };
        if (this.data[entity.id] === undefined) {
            this.groupId = entity.id;
            this.ajaxManager.ajaxCall('GET', url, this.getAjaxData(entity, entityType), callback.bind(this));
        } else {
            myApp.mapPrinter.activateMarker(marker, this.data[entity.id]);
        }

    }

    selectMarker(marker) {
        marker.classList.add('marker-selected');
        const icon = marker.querySelector('i.ci');
        if (icon) {
            icon.classList.remove('ci-s-13');
            icon.classList.add('ci-s-18');
        }
        document.querySelector('[data-own-infowindow]').style.display = 'block';
    }

    getAjaxData(group, entityType) {
        let chollosList = [];
        let cholloTmp;
        for (let chollo of group.chollos) {
            cholloTmp = {
                'id': chollo.id,
                'price': chollo.price,
                'dateId': chollo.dateId
            };
            chollosList.push(cholloTmp);
        }
        let urlParams = new URLSearchParams(window.location.search);
        return {
            groupId: group.id,
            chollosList: chollosList,
            cholloClass: group.class,
            startDate: urlParams.get('inicio'),
            endDate: urlParams.get('fin'),
            name: group.name,
            price: group.price,
            adults: urlParams.get('nA'),
            children: urlParams.get('nNe'),
            childrenAge: urlParams.get('edN'),
            distribution: urlParams.get('ha'),
            topCholloId: group.id,
            mealPlans: entityType === CHOLLO_TYPE ? group.categories.mealplan : null
        };
    }

    getElementLabel(group) {
        let label = document.createElement('div');
        let price = group.price.asc;
        for (let chollo of group.chollos) {
            if (!price || parseInt(price) > parseInt(chollo.price)) {
                price = chollo.price;
            }
        }
        label.appendChild(
            document.createTextNode(
                price + " €"
            )
        );
        return label;
    }
}
